html {
  height: 100%;
  padding: 0;
  font-family: sans-serif;
  margin: auto !important;
  scroll-behavior: smooth;
}

body, #__next {
  padding: 0;
  font-family: sans-serif;
  max-width: 1200px;
  margin: auto !important;
}

#__next {
  width: 100%!important;
  height: auto;
  position: relative;
  padding-bottom: 144px;
  margin: auto !important;
  display: grid;
  align-items: start;
  grid-gap: 0 0;
  grid-template-areas: 
  "top"
  "header"
  "leftSide"
  "main"
  "rightSide"
  "footer"
  "editors"
  ;
}

.top{
  grid-area: top;
  min-width: 0;
}
.header{
  grid-area: header;
  min-width: 0;
}
.leftSide{
  grid-area: leftSide;
  min-width: 0;
}
.main{
  grid-area: main;
  min-width: 0;
}
.rightSide{
  grid-area: rightSide;
  min-width: 0;
}
.footer{
  grid-area: footer;
  min-width: 0;
}
.editors{
  grid-area: editors;
  min-width: 0;
}
.hide{
  display: none
}
@media screen and (min-width: 960px) {
  html, body, #__next {
    font-family: sans-serif;
    margin: auto !important;
  }
  #__next {
    padding-bottom: 72px;
    display: grid;
    align-items: start;
    grid-gap: 0 0;
    grid-template-columns: auto 33% 33% auto;
    grid-template-areas: 
    "top top top top"
    "header header header header"
    "leftSide main main rightSide"
    "footer footer footer footer"
    "editors editors editors editors"
    ;
  }
}
a{
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}
/* centers a div in a page */
.center {
  display: flex;
  grid-area: main;
  justify-content: center;
  align-items: center;
  height:100vh;
  }